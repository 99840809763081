<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-1-2@s font-header">
        Riwayat {{ cage.data.name }} - Siklus {{ cycles.cycle_count }}
      </div>
    </div>

    <DataDetailRiwayatSiklus />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataDetailRiwayatSiklus from '../component/ContainerDetailSiklus'


export default {
  components: {
    DataDetailRiwayatSiklus
  },
  computed: {
    ...mapGetters({
      cage: 'activityCage/activityCageDetail',
      cycles: 'cycle/cycles'
    })
  },
  async mounted() {
    this.setCycleLoading(true)
    await this.getActivityCageDetail(this.$route.params.id)
    await this.getCycle({cage_id:this.cage.data.cage_id, log_cycle_id:this.$route.params.id})
    this.setCycleLoading(false)
  },
  methods: {
    ...mapActions({
      getActivityCageDetail: 'activityCage/getActivityCageDetail',
      getCycle: 'cycle/getCycle'
    }),
    ...mapMutations({
      setCycleLoading: 'cycle/SET_CYCLES_LOADING'
    })
  }
}
</script>

<style scoped>
.uk-subnav-pill > .uk-active > a {
  background-color: #ffffff;
  color: #005331 !important;
  border-radius: 10px;
  padding: 15px;
}

.uk-subnav {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  padding: 0;
  margin-bottom: -10px !important;
}

.uk-subnav-pill > * > :first-child {
  padding: 15px;
  background: #E0EBE6;
  color: #85AD9C;
  border-radius: 10px;
}

.uk-margin {
  margin-top: 0px !important;
}
</style>
