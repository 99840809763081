<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Siklus Ke
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="data.cylce_now"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Mulai Persiapan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="data.start_preparation"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Chick In
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="data.chick_in"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Mulai Panen
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="data.start_harvest"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Chick Out
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="data.chick_out"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Sanitasi
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="data.sanitation"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
      </div>

      <hr>

      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Populasi (ekor)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="population"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Ayam Mati (ekor)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dead_chicken"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Bobot Rataan (kg)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="average_weight"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Indek Prestasi (IP)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="ip"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
      </div>
      <TableSiklus />
      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-top"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-2@s uk-width-1-2@l uk-width-1-2@xl">
          <button
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/back.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Kembali
          </button>
        </div>
        <div class="uk-width-1-1 uk-width-1-2@s uk-width-1-2@l uk-width-1-2@xl uk-text-right">
          <button
            class="uk-button uk-button-primary uk-width-expand uk-width-auto@s"
            type="button"
            @click="csvExport"
          >
            <img
              v-lazy="`${images}/icon/export-white.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import TableSiklus from './TableSiklus'
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateUtcParanjeString, float3Decimals, dateParanjeCompleteString, thousandSeparator } from '@/utils/formater'

export default {
  components: {
    TableSiklus
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      data: {},
      activity: null,
      population: 0,
      dead_chicken: 0,
      average_weight: 0,
      ip: 0,
      dataCycle: {
        cycle: [],
        detail_cage: {}
      }
    }
  },
  computed: {
    ...mapGetters({
      cage: 'activityCage/activityCageDetail',
      cycles: 'cycle/cycles',
      cyclesNoLimit: 'cycle/cycles_no_limit'
    })
  },
  watch: {
    cycles() {
      if (this.cycles && this.cycles.length>0) {
        const currentCycle = this.cycles[this.cycles.length-1]
        this.activity = currentCycle.activity_name
      }
    },
    cage() {
      const cageData = this.cage.data ? this.cage.data : null
      const startPrepare = dateUtcParanjeString(cageData.start_preparation)
      const startHarvest = dateUtcParanjeString(cageData.start_harvest)
      const chickIn = dateUtcParanjeString(cageData.chick_in)
      const chickOut = dateUtcParanjeString(cageData.chick_out)
      const sanitation = dateUtcParanjeString(cageData.sanitation)

      cageData.start_preparation = startPrepare !== '01 Januari 1' ? startPrepare : ''
      cageData.start_harvest = startHarvest !== '01 Januari 1' ? startHarvest : ''
      cageData.chick_in = chickIn !== '01 Januari 1' ? chickIn : ''
      cageData.chick_out = chickOut !== '01 Januari 1' ? chickOut : ''
      cageData.sanitation = sanitation !== '01 Januari 1' ? sanitation : ''
      const cycleNow = this.cycles.cycle_count ? this.cycles.cycle_count : cageData.cycle_count
      this.data = {
        cage_id: cageData.cage_id,
        cylce_now: cycleNow,
        start_preparation: cageData.start_preparation,
        chick_in: cageData.chick_in,
        start_harvest: cageData.start_harvest,
        chick_out: cageData.chick_out,
        sanitation: cageData.sanitation
      }
      this.dead_chicken = cageData.dead_chicken ? cageData.dead_chicken : 0
      this.population = cageData.population ? cageData.population : 0
      this.average_weight = cageData.average_weight ? cageData.average_weight : 0
      this.ip = cageData.ip ? cageData.ip : 0
    },
    cyclesNoLimit () {
      this.cekFlag(this.cyclesNoLimit)
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      getCycleNoLimit: 'cycle/getCycleNoLimit'
    }),
    ...mapMutations({
      setModalDelete: 'user/SET_MODAL_DELETE'
    }),
    formatFloat(number) {
      return float3Decimals(number)
    },
    goToBack() {
      this.$router.push(`/admin/kandang-ayam/detail/${this.data.cage_id}`)
    },
    cekFlag (data) {
      this.dataCycle = {
        cycle: data.cycle,
        detail_cage: data.detail_cage
      }
      data.cycle.map((item, i) => {
        this.dataCycle.cycle[i].pakan_sak = item.pakan_sak != 0 ? item.pakan_sak : this.setValue(data.cycle_done_input[i].pakan_sak_is_done)
        this.dataCycle.cycle[i].group_deplesi_died = item.group_deplesi_died != 0 ? item.group_deplesi_died : this.setValue(data.cycle_done_input[i].group_deplesi_died_is_done)
        this.dataCycle.cycle[i].group_deplesi_culling = item.group_deplesi_culling != 0 ? item.group_deplesi_culling : this.setValue(data.cycle_done_input[i].group_deplesi_culling_is_done)
        this.dataCycle.cycle[i].group_body_weight_actual = item.group_body_weight_actual != 0 ? item.group_body_weight_actual : this.setValue(data.cycle_done_input[i].group_body_weight_actual_is_done)
      })
    },
    setValue (data) {
      const newData = data == 1 ? 0 : ''
      return newData
    },
    async csvExport() {
      await this.getCycleNoLimit({cage_id: this.cage.data.cage_id, log_cycle_id: this.cage.data.id})
      const arrData = {
        detail_cage: this.dataCycle.detail_cage,
        cycle: this.dataCycle.cycle
      }
      let newArray1 = []
      const newArray = []
      let newData = {}
      let csvContent = "data:text/csv;charset=utf-8,"

      newArray1 = [
        {
          title: 'Nama Kandang :',
          value: arrData.detail_cage.name
        },
        {
          title: 'Siklus ke :',
          value: arrData.detail_cage.log_cycle_count
        },
        {
          title: 'Mulai Persiapan :',
          value: dateParanjeCompleteString(arrData.detail_cage.start_preparation)
        },
        {
          title: 'Chick in :',
          value: dateParanjeCompleteString(arrData.detail_cage.chick_in)
        },
        {
          title: 'Mulai panen :',
          value: dateParanjeCompleteString(arrData.detail_cage.start_harvest)
        },
        {
          title: 'Chick out :',
          value: dateParanjeCompleteString(arrData.detail_cage.chick_out)
        },
        {
          title: 'Sanitasi :',
          value: dateParanjeCompleteString(arrData.detail_cage.sanitation)
        }
      ]
      csvContent += [
        Object.keys([]).join("\n"),
        ...newArray1.map((item) => Object.values(item).join(";")),
        Object.keys([]).join("\n"),
        Object.keys([]).join("\n"),
        Object.keys([]).join("\n")
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "")

      if (arrData.cycle !== null) {
        arrData.cycle.map(i => {
          newData = {
            'Tanggal': dateParanjeCompleteString(i.workdate),
            'Tahapan': i.activity_name,
            'Pakan (sak)': float3Decimals(i.pakan_sak),
            'Pakan (kg)': i.group_pakan_actual,
            'Ayam Mati (ekor)': i.group_deplesi_died,
            'Culling (ekor)': i.group_deplesi_culling,
            'Panen (ekor)': '',
            'Sisa Ayam': i.chic_left,
            'Bobot (Act)': float3Decimals(i.group_body_weight_actual),
            'Bobot (Std)': thousandSeparator(i.group_body_weight_standard),
            'Daily Intake (Act)': float3Decimals(i.group_daily_intake_actual),
            'Daily Intake (Std)': thousandSeparator(i.group_daily_intake_standard),
            'Feed Intake (Act)': float3Decimals(i.group_feed_intake_actual),
            'Feed Intake (Std)': thousandSeparator(i.group_feed_intake_standard),
            'FCR (Act)': float3Decimals(i.group_fcr_actual),
            'FCR (Std)': float3Decimals(i.group_fcr_standard),
            'Cum Dep (Act)': float3Decimals(i.group_cum_dep_actual),
            'Cum Dep (Std)': float3Decimals(i.group_cum_dep_standard),
            'Cum Dep (Ekor)': float3Decimals(i.group_cum_dep_left),
            'Indeks Prestasi': float3Decimals(i.ip_actual)
          }
          newArray.push(newData)
        })
        csvContent += [
          Object.keys(newArray[0]).join(";"),
          ...newArray.map(item => Object.values(item).join(";"))
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "")
      }

      const data = encodeURI(csvContent)
      const link = document.createElement("a")
      link.setAttribute("href", data)
      link.setAttribute("download", `Riwayat Siklus ke ${arrData.detail_cage.log_cycle_count} Kandang ${arrData.detail_cage.name} .csv`)
      link.click()
    }
  }
}
</script>
